import React from 'react';
import styled from 'styled-components';
import { Colors } from 'web-common/src/shared/Constants';
import { ifProp } from 'web-common/src/shared/styled-utils/ifProp';
import { FontSize, FontWeight } from 'web-common/src/shared/styles';

const Container = styled.div<{ removeBorder: boolean }>`
  border: ${ifProp('removeBorder', '0px', '1px solid #cacaca')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 84px;
  height: 32px;
  padding: 0 2px;
  background: white;

  font-weight: ${FontWeight.SEMI_BOLD};
  font-size: ${FontSize.S};
  color: ${Colors.GRAY_DARK_30};
`;

const Button = styled.button`
  border: none;
  background: none;
  color: ${Colors.RADISH};
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 0;

  &:disabled {
    color: ${Colors.GRAY};
  }
`;

interface QuantityPickerProps {
  disabled?: boolean;
  decrementDisabled?: boolean;
  incrementDisabled?: boolean;
  quantity: number;
  minQuantity?: number;
  maxQuantity?: number;
  increment: React.MouseEventHandler<HTMLButtonElement>;
  decrement: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  removeBorder?: boolean;
}

export const MultiCartQuantityPicker: React.FC<QuantityPickerProps> = ({
  disabled = false,
  decrementDisabled,
  incrementDisabled,
  decrement,
  increment,
  quantity,
  minQuantity,
  maxQuantity,
  className,
  removeBorder = false,
}) => {
  const decDisabled =
    disabled || decrementDisabled || quantity === 0 || (minQuantity != null && quantity <= minQuantity);
  const incDisabled = disabled || incrementDisabled || (maxQuantity != null && quantity >= maxQuantity);

  return (
    <Container className={className} removeBorder={removeBorder}>
      <Button disabled={decDisabled} onClick={decrement}>
        <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M20 12.5C20 13.0833 19.5769 13.5 19.0769 13.5H4.92308C4.38462 13.5 4 13.0833 4 12.5417C4 11.9583 4.38462 11.5 4.92308 11.5H19.0769C19.5769 11.5 20 11.9583 20 12.5Z'
            fill='currentColor'
          />
        </svg>
      </Button>
      <span className='mt-[3px]'>{quantity}</span>
      <Button disabled={incDisabled} onClick={increment}>
        <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M20 12.4615C20 13 19.5769 13.3846 19.0769 13.3846H12.9231V19.5385C12.9231 20.0769 12.5 20.5 12 20.5C11.4615 20.5 11.0769 20.0769 11.0769 19.5385V13.3846H4.92308C4.38462 13.3846 4 13 4 12.5C4 11.9615 4.38462 11.5385 4.92308 11.5385H11.0769V5.38462C11.0769 4.88462 11.4615 4.5 12 4.5C12.5 4.5 12.9231 4.88462 12.9231 5.38462V11.5385H19.0769C19.5769 11.5385 20 11.9615 20 12.4615Z'
            fill='currentColor'
          />
        </svg>
      </Button>
    </Container>
  );
};
