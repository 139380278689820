import { Slugs } from 'common/urls/Slugs';

const ADMIN_PATH = '/admin/';
const PHOTO_EDITOR_PATH = '/editor/';
const SHEF_PATH = '/shef/';
const CONSUMER_PATH = '/';
const CONSUMER_ORDER_PATH = '/order/';

// Used to indicate they just purchased
export const CONSUMER_ORDER_TYPE_QUERY_PARAM_JUST_PURCHASED = 'purchase';
export const CONSUMER_ORDER_TYPE_QUERY_PARAM_JUST_EDITED = 'edit';

export const FULL_QUERY_SLUG = `/:${Slugs.QUERY}?`;
const ID_SLUG = `:${Slugs.ID}/`;

const WAITLIST_ROOT = `${CONSUMER_PATH}waitlist/actions`;
export const ALL_WAITLIST_ROUTES = {
  WAITLIST_HOME: `${CONSUMER_PATH}waitlist/home`,
  WAITLIST_ACTIONS: WAITLIST_ROOT,
  WAITLIST_ACTIONS_REFER: `${WAITLIST_ROOT}/refer`,
};
export const ConsumerRoutes = {
  CAREERS: '/careers',
  COMMUNITY_GUIDELINES: `/community-guidelines`,
  CAREERS_DESTINATION: 'https://jobs.lever.co/shef',

  OUR_STORY: 'https://blog.shef.com/our-story',
  GLOBAL_CUISINES: 'https://shef.com/homemade-food/',

  CONSUMER_LANDING: CONSUMER_PATH,
  CONSUMER_REFER: `${CONSUMER_PATH}refer/:${Slugs.ID}`,
  CONSUMER_REFER_SHEF: `${CONSUMER_PATH}refer/:${Slugs.ID}/:${Slugs.SHEF_ID}`,
  CONSUMER_REFER_FOOD_ITEM: `${CONSUMER_PATH}refer/:${Slugs.ID}/:${Slugs.SHEF_ID}/:${Slugs.NAME}`,
  CONSUMER_REFER_ORDER: `${CONSUMER_PATH}refer/order/:${Slugs.ID}/:${Slugs.ORDER}`,
  CONSUMER_LOGIN: `${CONSUMER_PATH}login/:${Slugs.REDIRECT}?`,
  CONSUMER_FORGOT_PASSWORD: `${CONSUMER_PATH}forgot-password`,
  CONSUMER_RESET_CHANGE_PASSWORD: `${CONSUMER_PATH}reset-password/:${Slugs.ID}`,
  CONSUMER_BECOME_A_SHEF: `${CONSUMER_PATH}become-a-shef`,
  CONSUMER_BECOME_A_SHEF_REGISTER: `${CONSUMER_PATH}become-a-shef/register`,
  DEPRECATED_CONSUMER_COMING_SOON: `${CONSUMER_PATH}coming-soon/:${Slugs.ZIP_CODE}`, // Use WAITLIST_HOME instead

  // Celebrity Events
  BINGING_WITH_BABISH: `${CONSUMER_PATH}binging-with-babish`,
  MASTER_CHEF_NYESHA: `${CONSUMER_PATH}nyesha`,

  // Permanent/Temporary Register pages
  CONSUMER_REGISTER: `${CONSUMER_PATH}register/:${Slugs.REDIRECT}?`,

  // Explore
  CONSUMER_EXPLORE: `${CONSUMER_PATH}explore/zip/:${Slugs.ZIP_CODE}`,
  CONSUMER_EXPLORE_CUISINE: `${CONSUMER_PATH}explore/zip/:${Slugs.ZIP_CODE}/:${Slugs.CUISINE}`,
  CONSUMER_MULTIPLE_CARTS_VIEW: `${CONSUMER_PATH}explore/cart`,
  CONSUMER_EXPLORE_CUISINE_SEARCH: `${CONSUMER_PATH}explore/cuisine/:${Slugs.CUISINE}`,
  // eslint-disable-next-line max-len
  CONSUMER_EXPLORE_MERCHANDISE: `${CONSUMER_PATH}explore/collection/:${Slugs.MERCHANDISE_PAGE_SLUG}/zip/:${Slugs.ZIP_CODE}`,

  CONSUMER_FOLLOWING: `${CONSUMER_PATH}following`,

  CONSUMER_ORDER_FINALIZE: `${CONSUMER_ORDER_PATH}finalize`,
  CONSUMER_ORDER_ONE_TIME: `${CONSUMER_ORDER_PATH}shef/:${Slugs.ID}`,
  CONSUMER_ORDER_REVIEWS: `${CONSUMER_ORDER_PATH}shef/:${Slugs.ID}/reviews`,
  CONSUMER_ORDER_FOOD_ITEM: `${CONSUMER_ORDER_PATH}shef/:${Slugs.ID}/:${Slugs.NAME}`,
  CONSUMER_ORDER_CART: '/cart',
  CONSUMER_ORDER_EDIT_DELIVERY: `${CONSUMER_ORDER_PATH}:${Slugs.ID}/edit-delivery`,
  CONSUMER_ORDER_EDIT_LINE_ITEMS: `${CONSUMER_ORDER_PATH}:${Slugs.ID}/edit-items`,
  CONSUMER_ORDER_EDIT_FOOD_ITEM: `${CONSUMER_ORDER_PATH}:${Slugs.ID}/edit-items/:${Slugs.NAME}`,
  CONSUMER_ORDER_EDIT_CART: `${CONSUMER_ORDER_PATH}:${Slugs.ID}/cart`,
  CONSUMER_ORDER_CANCEL: `${CONSUMER_ORDER_PATH}:${Slugs.ID}/cancel`,
  CONSUMER_ORDER_CONFIRMATION: `${CONSUMER_ORDER_PATH}:${Slugs.ID}/confirmation`,
  CONSUMER_ORDERS_CONFIRMATION: `${CONSUMER_ORDER_PATH}confirmation`,
  CONSUMER_ORDER_RECEIPT: `${CONSUMER_ORDER_PATH}:${Slugs.ID}/receipt`,
  CONSUMER_ORDER_SEARCH: `${CONSUMER_ORDER_PATH}search/zip/:${Slugs.ZIP_CODE}`,

  CONSUMER_ORDER_GROUP_ORDER_EDIT: `${CONSUMER_ORDER_PATH}:${Slugs.ID}/edit-group-order/:${Slugs.SHEF_ID}`,
  CONSUMER_ORDER_EDIT_GROUP_ORDER_FOOD_ITEM: `${CONSUMER_ORDER_PATH}:${Slugs.ID}/edit-group-order/:${Slugs.SHEF_ID}/:${Slugs.NAME}`,

  CONSUMER_REORDER: `${CONSUMER_PATH}reorder/:${Slugs.ID}`,

  // Consumer/Account
  CONSUMER_ACCOUNT_PROFILE: `${CONSUMER_PATH}account/profile`,
  CONSUMER_ACCOUNT_ORDERS: `${CONSUMER_PATH}account/orders/upcoming`,
  CONSUMER_ACCOUNT_MESSAGES: `${CONSUMER_PATH}account/messages`,
  CONSUMER_ACCOUNT_SHARE: `${CONSUMER_PATH}account/share`,
  CONSUMER_ACCOUNT_SHARE_SHEF: `${CONSUMER_PATH}account/share/:${Slugs.SHEF_ID}`,
  CONSUMER_ACCOUNT_SHARE_FOOD_ITEM: `${CONSUMER_PATH}account/share/:${Slugs.SHEF_ID}/:${Slugs.NAME}`,
  CONSUMER_ACCOUNT_SHARE_ORDER: `${CONSUMER_PATH}account/share/order/:${Slugs.ORDER}`,
  CONSUMER_ACCOUNT_PAYMENT_METHODS: `${CONSUMER_PATH}account/payment-methods`,
  CONSUMER_ACCOUNT_DELIVERY_ADDRESSES: `${CONSUMER_PATH}account/delivery-addresses`,
  CONSUMER_ACCOUNT_SUBSCRIPTION: `${CONSUMER_PATH}account/subscription`,
  CONSUMER_ACCOUNT_NOTIFICATIONS: `${CONSUMER_PATH}account/notifications`,
  CONSUMER_MEAL_PLANS_INDIAN_REDIRECT: `${CONSUMER_PATH}meal-plans/indian`,
  CONSUMER_MEAL_PLANS_YAF_REDIRECT: `${CONSUMER_PATH}meal-plans/personal-shef`,
  CONSUMER_MEAL_PLANS: `${CONSUMER_PATH}meal-plans`,
  CONSUMER_MEAL_PLANS_CHECKOUT: `${CONSUMER_PATH}meal-plans/checkout`,
  CONSUMER_MEAL_PLANS_EDIT: `${CONSUMER_PATH}meal-plans/edit`,
  CONSUMER_MEAL_PLANS_PREFERENCES: `${CONSUMER_PATH}meal-plans/preferences`,
  CONSUMER_COMPLETE_MEAL_PLANS_CHECKOUT: `${CONSUMER_PATH}meal-plans/complete-meal/checkout`,
  CONSUMER_COMPLETE_MEAL_PLANS_PREFERENCES: `${CONSUMER_PATH}meal-plans/complete-meal/preferences`,
  CONSUMER_MEAL_PLANS_UPCOMING_ORDER: `${CONSUMER_PATH}meal-plans/upcoming-order`,
  CONSUMER_MEAL_PLANS_MANAGE: `${CONSUMER_PATH}meal-plans/manage`,
  CONSUMER_MEAL_PLANS_MANAGE_PLAN: `${CONSUMER_PATH}meal-plans/manage/plan`,
  CONSUMER_MEAL_PLANS_MANAGE_DELIVERY: `${CONSUMER_PATH}meal-plans/manage/delivery`,

  CONSUMER_PRIVACY_POLICY: `${CONSUMER_PATH}privacy-policy`,
  CONSUMER_TOS: `${CONSUMER_PATH}terms-of-service`,
  CONSUMER_FAQ: `${CONSUMER_PATH}frequently-asked-questions`,
  CONSUMER_JUN_2022_PICKUP_ONLY_MARKET_INFO: `${CONSUMER_PATH}pickup-only-info`,
  CONSUMER_FEE_POLICY: `${CONSUMER_PATH}fee-policy`,

  CONSUMER_GIFT_CARD: `${CONSUMER_PATH}gift`,

  // Subscription signup
  CONSUMER_SUBSCRIPTION_LANDING_PAGE: `${CONSUMER_PATH}subscribe`,
  CONSUMER_SUBSCRIPTION_WIZARD_PAGE: `${CONSUMER_PATH}subscribe/:${Slugs.ZIP_CODE}/steps/:${Slugs.STEP_NUMBER}`,

  // Subscription account
  CONSUMER_SUBSCRIPTION_RECEIPT: `${CONSUMER_PATH}subscription/receipt`,
  CONSUMER_SUBSCRIPTION_CANCEL: `${CONSUMER_PATH}subscription/cancel`,
  CONSUMER_SUBSCRIPTION_EDIT_PLAN: `${CONSUMER_PATH}subscription/plan`,
  CONSUMER_SUBSCRIPTION_EDIT_DIET: `${CONSUMER_PATH}subscription/diet`,
  CONSUMER_SUBSCRIPTION_EDIT_CUISINE: `${CONSUMER_PATH}subscription/cuisine`,

  // Subscription orders
  CONSUMER_SUBSCRIPTION_SCHEDULE: `${CONSUMER_PATH}subscription`,
  CONSUMER_SUBSCRIPTION_INVOICE_DELIVERY: `${CONSUMER_PATH}subscription/:${Slugs.INVOICE_ID}/delivery`,
  CONSUMER_SUBSCRIPTION_DELIVERY_TRACKING: `${CONSUMER_PATH}subscription/:${Slugs.INVOICE_ID}/tracking`,
  CONSUMER_SUBSCRIPTION_INVOICE_MEALS: `${CONSUMER_PATH}subscription/:${Slugs.INVOICE_ID}/meals`,

  // Deprecated routes
  DEPRECATED_CONSUMER_EXPLORE: `${CONSUMER_PATH}explore/zip/:${Slugs.ZIP_CODE}${FULL_QUERY_SLUG}`,

  // Personal shef
  CONSUMER_PERSONAL_SHEF: `${CONSUMER_PATH}personal-shef`,
  CONSUMER_PERSONAL_SHEF_FORM: `${CONSUMER_PATH}personal-shef/preferences`,

  // Waitlists
  ...ALL_WAITLIST_ROUTES,
};

export const ConsumerLoginRoutes = [ConsumerRoutes.CONSUMER_REGISTER, ConsumerRoutes.CONSUMER_LOGIN];

export const ConsumerSubscriptionSignUpRoutes = [
  ConsumerRoutes.CONSUMER_SUBSCRIPTION_LANDING_PAGE,
  ConsumerRoutes.CONSUMER_SUBSCRIPTION_WIZARD_PAGE,
];

export const ConsumerSubscriptionPostSignupRoutes = [
  ConsumerRoutes.CONSUMER_SUBSCRIPTION_RECEIPT,
  ConsumerRoutes.CONSUMER_SUBSCRIPTION_CANCEL,
  ConsumerRoutes.CONSUMER_SUBSCRIPTION_EDIT_PLAN,
  ConsumerRoutes.CONSUMER_SUBSCRIPTION_EDIT_DIET,
  ConsumerRoutes.CONSUMER_SUBSCRIPTION_EDIT_CUISINE,
  ConsumerRoutes.CONSUMER_SUBSCRIPTION_SCHEDULE,
  ConsumerRoutes.CONSUMER_SUBSCRIPTION_INVOICE_DELIVERY,
  ConsumerRoutes.CONSUMER_SUBSCRIPTION_DELIVERY_TRACKING,
  ConsumerRoutes.CONSUMER_SUBSCRIPTION_INVOICE_MEALS,
];

export const ConsumerSubscriptionRoutes = [
  ...ConsumerSubscriptionSignUpRoutes,
  ...ConsumerSubscriptionPostSignupRoutes,
];

export const ShefRouteHashes = {
  SHEF_PROFILE_ADDRESS: 'shef-profile-address',
  SHEF_CUISINE_PROFILE: 'shef-cuisine-profile',
};

const SHEF_APPLICATION_PATH = `${SHEF_PATH}application`;
export const ShefRoutes = {
  SHEF: SHEF_PATH,
  SHEF_PROFILE: `${SHEF_PATH}profile`,
  SHEF_PROFILE_ADDRESS: `${SHEF_PATH}profile#${ShefRouteHashes.SHEF_PROFILE_ADDRESS}`,
  SHEF_CUISINE_PROFILE: `${SHEF_PATH}profile#${ShefRouteHashes.SHEF_CUISINE_PROFILE}`,
  SHEF_APPLICATION: SHEF_APPLICATION_PATH,
  SHEF_APPLICATION_STEP: `${SHEF_APPLICATION_PATH}/${ID_SLUG}`,
  SHEF_APPLICATION_BIO: `${SHEF_APPLICATION_PATH}/bio`,
  SHEF_APPLICATION_PHOTO: `${SHEF_APPLICATION_PATH}/photo`,
  SHEF_APPLICATION_ADDRESS: `${SHEF_APPLICATION_PATH}/address`,
  SHEF_APPLICATION_CALL: `${SHEF_APPLICATION_PATH}/call`,
  SHEF_CHECKLIST: `${SHEF_PATH}checklist`,
  SHEF_CHECKLIST_INTRO_VIDEO: `${SHEF_PATH}checklist/intro`,
  SHEF_SCHEDULE_CALL: `${SHEF_PATH}schedule-call`,

  SHEF_AVAILABILITY: `${SHEF_PATH}availability`,
  SHEF_SELF_PROMOTION: `${SHEF_PATH}promote`,
  SHEF_MESSAGES_ACTIVE_MESSAGE: `${SHEF_PATH}messages/:${Slugs.ID}`,
  SHEF_MESSAGES: `${SHEF_PATH}messages`,

  SHEF_ALL_ORDERS: `${SHEF_PATH}orders/all`,
  SHEF_ORDER: `${SHEF_PATH}order/${ID_SLUG}`,
  SHEF_ORDER_QA: `${SHEF_PATH}order/:id/qa`,
  SHEF_SCANNER: `${SHEF_PATH}scanner`,

  SHEF_FOOD_ITEM: `${SHEF_PATH}food-items/${ID_SLUG}`,
  SHEF_ALL_FOOD_ITEMS: `${SHEF_PATH}food-items/all`,

  SHEF_RATINGS: `${SHEF_PATH}ratings`,
  SHEF_INSIGHTS: `${SHEF_PATH}insights`,
  SHEF_GROW_REFERRALS: `${SHEF_PATH}grow-your-business/referrals`,
  SHEF_GROW_INSIGHTS: `${SHEF_PATH}grow-your-business/insights`,
  SHEF_GROW_YOUR_BUSINESS_PATH: `${SHEF_PATH}grow-your-business`,
  SHEF_GROW_YOUR_BUSINESS: `${SHEF_PATH}grow-your-business/:tab`,
  SHEF_PROMOTIONS_CREATE: `${SHEF_PATH}grow-your-business/promotions/create`,
  SHEF_PROMOTIONS_MANAGE: `${SHEF_PATH}grow-your-business/promotions/manage`,
  SHEF_EARNINGS: `${SHEF_PATH}earnings`,
  SHEF_FOLLOWING: `${SHEF_PATH}following`,
  SHEF_STATS: `${SHEF_PATH}stats`,
  SHEF_SOCIAL: `${SHEF_PATH}social`,

  SHEF_SOPS: `${SHEF_PATH}help`,
  SHEF_FAQS_NEW: 'https://help.shef.com/en/',
  SHEF_HELP_CENTER_NEW: 'https://help.shef.com/en/',
  SHEF_ORDERS_BY_DATE: `${SHEF_PATH}orders/:${Slugs.DATE}`,
  SHEF_ORDER_LABELS: `${SHEF_PATH + ID_SLUG}labels/:${Slugs.DATE}`,
  SHEF_DELIVERY_DAY_APP: `${SHEF_PATH + ID_SLUG}deliveries/:${Slugs.DATE}`,

  SHEF_ACCELERATOR_TOS: `${SHEF_PATH}accelerator/tos`,
};

export const AdminRoutes = {
  ADMIN: ADMIN_PATH,
  ADMIN_CONSUMERS: `${ADMIN_PATH}consumers${FULL_QUERY_SLUG}`,
  ADMIN_SHEFS: `${ADMIN_PATH}shefs${FULL_QUERY_SLUG}`,
  ADMIN_SHEFS_FOODHANDLERS: `${ADMIN_PATH}foodhandlers${FULL_QUERY_SLUG}`,

  ADMIN_SHEF_PROFILE: `${ADMIN_PATH}users/${ID_SLUG}shef-profile`,
  ADMIN_SHEF_ORDERS_BY_DATE: `${ADMIN_PATH}users/${ID_SLUG}shef/orders/:${Slugs.DATE}`,
  ADMIN_USER: `${ADMIN_PATH}users/${ID_SLUG}user`,

  ADMIN_FOOD_ITEM: `${ADMIN_PATH}food-items/${ID_SLUG}`,
  ADMIN_ALL_FOOD_ITEMS: `${ADMIN_PATH}food-items/all`,
  ADMIN_FOOD_ITEM_AUDIT: `${ADMIN_PATH}food-item-audits/${ID_SLUG}`,
  ADMIN_ALL_FOOD_ITEM_AUDITS: `${ADMIN_PATH}food-item-audits/all`,

  ADMIN_CHANGE_REQUEST: `${ADMIN_PATH}change-requests/${ID_SLUG}`,
  ADMIN_ALL_CHANGE_REQUESTS: `${ADMIN_PATH}change-requests/all`,

  ADMIN_ALL_ORDERS: `${ADMIN_PATH}orders/all`,
  ADMIN_ORDER: `${ADMIN_PATH}orders/${ID_SLUG}`,
  ADMIN_ORDER_LABELS: `${ADMIN_PATH + ID_SLUG}labels/:${Slugs.DATE}`,
  ADMIN_ALL_ORDER_LABELS: `${ADMIN_PATH}labels/all`,

  ADMIN_ALL_PROMOTIONAL_CODES: 'https://shef.retool.com/apps/Promo%20Codes',
  ADMIN_ALL_PROMO_CODE_USAGE: 'https://smart.shef.com/question/51',

  ADMIN_CONFIGS: `${ADMIN_PATH}configs`,
};

export const PhotoEditorRouter = {
  PHOTO_EDITOR: PHOTO_EDITOR_PATH,
  PHOTO_EDITOR_REQUESTS: `${PHOTO_EDITOR_PATH}requests`,
  PHOTO_EDITOR_FOOD_ITEM: `${PHOTO_EDITOR_PATH}request/:${Slugs.ID}`,
};

export const Redirects = [
  {
    from: `${ADMIN_PATH}promotional-codes`,
    to: AdminRoutes.ADMIN_ALL_PROMOTIONAL_CODES,
  },
  { from: `/recycling`, to: `https://o.shef.com/recycling` },
];

export const Routes = {
  LOGOUT: `/logout/:${Slugs.REDIRECT}?`,
  BLOG: 'https://shef.com/homemade-food/the-shef-blog/',
  HOMEMADE_FOOD_DELIVERY: '/homemade-food-delivery',
  FOOD_SAFETY: 'https://shef.com/homemade-food/food-safety/',
  HELP_FAQ_PATH: 'https://help.shef.com/en/',
  EMAIL_TO_SUPPORT: 'mailto:support@shef.com',
  ...PhotoEditorRouter,
  ...ConsumerRoutes,
  ...ShefRoutes,
  ...AdminRoutes,
};

export const SitemapRoutes = [
  ConsumerRoutes.CONSUMER_LANDING,
  ConsumerRoutes.CONSUMER_BECOME_A_SHEF,
  ConsumerRoutes.CONSUMER_ORDER_ONE_TIME,
];

// URLS Used for redirecting for marketing campaigns / qr codes
export const VANITY_URLS = {
  BACK_TO_SCHOOL: '/back-to-school',
  HOMEMADE: '/homemade',
  VARIETY: '/variety',

  FERRY_LEARN_MORE: '/ferry-learn-more',

  CONSUMER_REGISTER_FERRY_INDIAN: '/ferry-discover-indian-cuisine',
  CONSUMER_REGISTER_FERRY_THAI: '/ferry-discover-thai-cuisine',
  CONSUMER_REGISTER_FERRY_MEXICAN: '/ferry-discover-mexican-cuisine',
  CONSUMER_REGISTER_FERRY_EGYPTIAN: '/ferry-discover-egyptian-cuisine',
  CONSUMER_REGISTER_FERRY_ALL: '/ferry-discover-all-cuisine',

  FOOD_FASHION_PARTY: '/foodfashionparty',
  JC_EEATS: '/jc-eeats',
  RACHEL_KAWATE: '/rachelkawate',
  DID_U_DROOL: '/did-u-drool',
  LESS_WITH_LAUR: '/lesswithlaur',

  INFLUENCER_ALEX: '/Alex-Mutammara',
  INFLUENCER_JEN: '/Jenhdao',
  INFLUENCER_CARLA: '/Carla-Silva',
  INFLUENCER_GRACE: '/journalbymoon',
  INFLUENCER_SEREIN: '/Serein-Wu',
  INFLUENCER_ELLA: '/Ella-Elbells',
  INFLUENCER_SHEILA: '/worldthroughshe',
  INFLUENCER_KANA: '/kanainlosangeles',
  INFLUENCER_SHEREEN: '/therazicks',
};

export const VanityRoutes = [...Object.values(VANITY_URLS)];
