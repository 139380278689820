import { UserIdentifierType } from '../analytics-event-manager/IAnalyticsEventManager';
import { CONTROL_VARIANT } from './ExperimentManager';
import { IExperiment } from './IExperimentManager';

export const EXP_VAR_TREATMENT = 'treatment';

export enum ExperimentNames {
  PLACEHOLDER = 'PLACEHOLDER', // unused placeholder to ensure correct types
  BULK_DISCOUNT = 'BULK_DISCOUNT ',
  NEW_PROMO_CODE = 'NEW_PROMO_CODE ',
  POST_CHECKOUT_UPSELL_RECS = 'POST_CHECKOUT_UPSELL_RECS',
  ANY_DAY_DELIVERY = 'ANY_DAY_DELIVERY',
  HAMBURGER_MENU_REDESIGN = 'HAMBURGER_MENU_REDESIGN',
  MEAL_PLAN_HOMEPAGE_ROUTING_V1 = 'MEAL_PLAN_HOMEPAGE_ROUTING_V1',
}

export const ActiveExperiments: readonly IExperiment[] = [
  {
    name: ExperimentNames.BULK_DISCOUNT,
    userIdentifierType: UserIdentifierType.DEVICE_ID,
    rollout: 100,
    variants: [
      {
        name: CONTROL_VARIANT,
        weight: 0,
      },
      {
        name: EXP_VAR_TREATMENT,
        weight: 100,
      },
    ],
  },
  {
    name: ExperimentNames.NEW_PROMO_CODE,
    userIdentifierType: UserIdentifierType.DEVICE_ID,
    rollout: 0,
    variants: [
      {
        name: CONTROL_VARIANT,
        weight: 50,
      },
      {
        name: EXP_VAR_TREATMENT,
        weight: 50,
      },
    ],
  },
  {
    name: ExperimentNames.POST_CHECKOUT_UPSELL_RECS,
    userIdentifierType: UserIdentifierType.DEVICE_ID,
    rollout: 0,
    variants: [
      {
        name: CONTROL_VARIANT,
        weight: 50,
      },
      {
        name: EXP_VAR_TREATMENT,
        weight: 50,
      },
    ],
  },
  {
    name: ExperimentNames.ANY_DAY_DELIVERY,
    userIdentifierType: UserIdentifierType.DEVICE_ID,
    rollout: 100,
    variants: [
      {
        name: CONTROL_VARIANT,
        weight: 0,
      },
      {
        name: EXP_VAR_TREATMENT,
        weight: 100,
      },
    ],
  },
  {
    name: ExperimentNames.HAMBURGER_MENU_REDESIGN,
    userIdentifierType: UserIdentifierType.USER_ID,
    rollout: 0,
    variants: [
      {
        name: CONTROL_VARIANT,
        weight: 100,
      },
      {
        name: EXP_VAR_TREATMENT,
        weight: 0,
      },
    ],
  },
  {
    name: ExperimentNames.MEAL_PLAN_HOMEPAGE_ROUTING_V1,
    userIdentifierType: UserIdentifierType.DEVICE_ID,
    rollout: 0,
    variants: [
      {
        name: CONTROL_VARIANT,
        weight: 50,
      },
      {
        name: EXP_VAR_TREATMENT,
        weight: 50,
      },
    ],
  },
];
