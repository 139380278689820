import styled from 'styled-components';
import { BorderRadius, Colors, LineHeight, Spacing } from 'web-common/src/shared/styles';
import { BaseTextArea } from '../BaseTextArea';

export const TextAreaV2 = styled(BaseTextArea)`
  background-color: ${Colors.WHITE};
  border-radius: ${BorderRadius.M};
  border: 1px solid ${Colors.GRAY_DARK_10};
  line-height: ${LineHeight.S};
  min-height: 120px;
  padding: ${Spacing.SINGLE};
  width: 100%;

  &::placeholder {
    color: ${Colors.GRAY_DARK_10};
  }
`;
export const TextAreaV3 = styled(BaseTextArea)`
  background-color: ${Colors.NEUTRAL_50};
  border-radius: ${BorderRadius.M};
  line-height: ${LineHeight.S};
  min-height: 120px;
  padding: ${Spacing.SINGLE};
  width: 100%;

  &::placeholder {
    color: ${Colors.GRAY_DARK_10};
  }
`;
